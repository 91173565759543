import React from 'react';
import { format } from '@components/utilities';
import styled from 'styled-components';

const StyledImg = styled.img`
width: 100%;
height: 160px;
object-fit: cover;
`;

const MyCourseList = (props) => {
  const { filteredEntities } = props;

  const getPrincipalImage = (_entity) => {
    const defaultItem = {
      uri: `${process.env.REACT_APP_BACKEND_URL}/img/404.svg`,
      name:
        !_entity || !_entity.name
          ? 'Entidad no disponible'
          : `Adjunto inexistente de "${_entity.name}"`,
      attachmentType: 'image/svg+xml',
    };

    if (!_entity || !_entity.attachments || !_entity.attachments.length) {
      return defaultItem;
    }

    const element = [..._entity.attachments].find(
      (el) => el.attachmentType.includes('image') && el.isPrincipal === true,
    );

    if (!element || !element.uri) {
      return defaultItem;
    }

    return element;
  };

  const getBody = (body) => {
    if (!body) {
      return '';
    }
    var text = format.extractTextFromHtml(decodeURI(body));
    return text.length > 85 ? text.slice(0, 85) + '...' : text;
  };

  return (
    <div className="d-flex row">
      {filteredEntities.map((_entity, index) => (
        <article className="col-12 col-md-4 my-3" key={index}>
          <div className="card d-flex h-100 justify-content-between shadow">
            <StyledImg
              src={
                getPrincipalImage(_entity).uri ||
                getPrincipalImage(_entity).filename
              }
              alt="card"
            />
            <section className="card-body d-flex flex-column justify-content-between">
              <div className="w-100">
                <h5 className="card-title fs-4">{_entity.name}</h5>
                <p className="card-text">{getBody(_entity.description)}</p>
              </div>
              <footer className="mt-3 text-center w-100">
                <a
                  className="btn btn-primary"
                  target="_blank"
                  rel="noreferrer"
                  href={`/student/course/detail/${_entity.id}`}
                >
                  Ver más
                </a>
              </footer>
            </section>
          </div>
        </article>
      ))}
    </div>
  );
};

export default MyCourseList;
