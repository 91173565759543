import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth.hook';
import Alert from '@components/shared/alert/Alert';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import { CourseList, MyCourseList } from '@components/pages';
import { CourseService } from '@services';

const Dashboard = () => {
  const { setPageName } = useOutletContext();
  const { user } = useAuth();

  const [entities, setEntities] = useState([]);
  const [myCourses, setMyCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredEntities, setFilteredEntities] = useState([]);
  const [filteredMyCourses, setFilteredMyCourses] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [view, setView] = useState('');
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  const getAllEntities = async () => {
    try {
      const entityService = new CourseService();

      const entityResponse = await entityService.get({
        all: true,
        filterBy: [
          {
            key: 'status.name',
            value: 'active|available',
          },
        ],
      });
      const myCoursesResponse = await entityService.getMyCourses({
        user_id: user.id,
      });

      if (!entityResponse || !entityResponse.success) {
        setAlertInfo({
          message: 'Algo salió mal, por favor intente de nuevo.',
          type: 'error',
        });
        return;
      }

      setEntities(entityResponse.result);
      setMyCourses(myCoursesResponse.result || []);
      resetFilteredEntities();
    } catch (error) {
      console.error(error);
      setAlertInfo({
        message: 'Algo salió mal, por favor intente de nuevo.',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPageName('Inicio');
    getAllEntities();
  }, []);

  useEffect(() => {
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  useEffect(() => {
    if (!searchText) {
      resetFilteredEntities();
      return;
    }

    let newFilteredEntities = [];
    let newFilteredMyCourses = [];

    switch (view) {
      case 'courses':
        newFilteredEntities = entities.filter((item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()),
        );
        break;
      case 'myCourses':
        newFilteredMyCourses = myCourses.filter((item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()),
        );
        break;
      default:
        break;
    }

    setFilteredEntities(newFilteredEntities);
    setFilteredMyCourses(newFilteredMyCourses);
  }, [searchText, entities, myCourses, view]);

  const navigationOnClick = (newView) => {
    setView(newView);
    setSearchText('');
  };

  const resetFilteredEntities = () => {
    setFilteredEntities(entities);
    setFilteredMyCourses(myCourses);
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  return (
    <div className="col-12 mx-auto d-block">
      <div className="widget card">
        {alertInfo.type && (
          <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="row d-flex">
              <div className="col-12 d-block mx-auto py-2">
                <div className="d-flex my-4 justify-content-between">
                  <ul className="nav nav-tabs nav-bordered" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="courses-tab"
                        data-bs-toggle="tab"
                        href="#courses"
                        role="tab"
                        aria-controls="courses"
                        aria-selected="true"
                        onClick={() => navigationOnClick('courses')}
                      >
                        Cursos
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="mycourses-tab"
                        data-bs-toggle="tab"
                        href="#mycourses"
                        role="tab"
                        aria-controls="mycourses"
                        aria-selected="false"
                        onClick={() => navigationOnClick('myCourses')}
                      >
                        Mis cursos
                      </a>
                    </li>
                  </ul>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="search-course"
                      id="search-course"
                      placeholder="Buscar..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>

                <div className="tab-content" id="myTabContent">
                  {/* Todos los cursos */}
                  <section
                    className="active fade show tab-pane"
                    id="courses"
                    role="tabpanel"
                    aria-labelledby="courses-tab"
                  >
                    <CourseList filteredEntities={filteredEntities} />
                  </section>

                  {/* Mis cursos */}
                  <section
                    className="tab-pane fade"
                    id="mycourses"
                    role="tabpanel"
                    aria-labelledby="mycourses-tab"
                  >
                    <MyCourseList filteredEntities={filteredMyCourses} />
                  </section>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
