import React from 'react';
import { CertificationDetail } from '@components/pages/';

const CertificationDetailView = () => {
  return (
    <div className="container-fluid">
      <section className="card card-body">
        <CertificationDetail />
      </section>
    </div>
  );
};

export default CertificationDetailView;
