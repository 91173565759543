import React from 'react';
import { ExamDetail } from '@components/pages';

function ExamDetailView() {
  return (
    <article className="col-12">
      <section className="card">
        <section className="card-body">
          <section className="row">
            <ExamDetail />
          </section>
        </section>
      </section>
    </article>
  );
}

export default ExamDetailView;
