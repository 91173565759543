import React from 'react';
import { Navigate } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth.hook';

function AuthBridge () {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/auth/login" />;
  } else {
    return <Navigate to="/student" />;
  }
  return (
    <></>
  );
}

export default AuthBridge;
