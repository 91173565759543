import React, { useState, useEffect } from 'react';
import { CertificationService } from '@services/index';
import { useParams } from 'react-router-dom';
import Alert from '@components/shared/alert/Alert';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';

const CertificationDetail = () => {
  const { id: certId } = useParams();
  const [entity, setEntity] = useState({});
  const [isConfirm, setIsConfirm] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  const showErrorAlert = (message) => setAlertInfo({ message, type: 'error' });

  useEffect(() => {
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const entityService = new CertificationService();
        const entityResponse = await entityService.get({
          id: certId,
        });

        if (!entityResponse || !entityResponse.success) {
          showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
          return;
        }

        setEntity(entityResponse.result);
      } catch (error) {
        console.error(error);
        showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [certId]);

  const formatDate = (timestamp) => {
    const date = new Date(+timestamp);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'success':
        return {
          title: 'Success',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  return (
    <div className="col-12 col-md-10 grid-margin-md-0 stretch-card mx-auto d-block">
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      <div className="card">
        <div className="card-body">
          {isLoading ? (
            <SpinnerLoader />
          ) : (
            <>
              <div className="mb-4">
                <h4 className="text-center">{entity?.issuer} Constancia que</h4>
                <h2 className="text-center">{`${entity.user?.firstname} ${entity.user?.lastname}`}</h2>
                <h6 className="text-center fs-4">
                  Ha finalizado del curso "{entity.course?.name}" en la
                  fecha
                  <span className="ms-1">
                    {formatDate(entity.date_creation)}
                  </span>
                </h6>
              </div>

              {/* Files */}
              <section className="d-flex flex-column justify-content-center">
                {entity.attachments?.map((_entity, index) => (
                  <div key={index} className="d-flex justify-content-center">
                    <a
                      className="btn btn-primary my-3"
                      target="_blank"
                      href={_entity.uri}
                      rel="noopener noreferrer"
                    >
                      Descargar adjunto
                    </a>
                  </div>
                ))}
              </section>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CertificationDetail;
