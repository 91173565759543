import BaseApi from '../base/apiBase.service';

export default class UserExamService extends BaseApi {
  constructor(args) {
    super(args);

    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_BACKEND_URL,
      get: '/api/user-exam',
      create: '/api/user-exam',
      update: '/api/user-exam',
      exportAll: '/api/user-exam',
    };
  }

  async getByParameters(data) {
    return super.getByParameters(data);
  }  

  async update(data) {
    return super.update(data);
  }

  async create(data) {
    return super.create(data);
  }

  async exportAll(data) {
    return super.get(data, this.serviceEndpoints.exportAll);
  }

  async get(data) {
    return super.get(data, this.serviceEndpoints.get);
  }
}
