import React from "react";
import { useAuth } from "../../hooks/useAuth.hook";

function NavbarAdmin (props) {
  const { user } = useAuth();

  return (
    <div className="navbar-custom">
      <ul className="list-unstyled topnav-menu float-end mb-0">

        <li className="dropdown notification-list topbar-dropdown">
          <a className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false" >
            <img  src={user.profile_pic_uri || '/assets/images/characters/character-unknown.svg'} alt="user-image" className="rounded-circle bg-warning bg-moved-down" />
            <span className="pro-user-name ms-1">
              {user.firstname} <i className="mdi mdi-chevron-down"></i>
            </span>
          </a>
          <div className="dropdown-menu dropdown-menu-end profile-dropdown ">

            {/* <!-- item--> */}
            <a href="/student/account/overview" className="dropdown-item notify-item">
              <i className="fe-user"></i>
              <span>Mi cuenta</span>
            </a>

            {/* <!-- item--> */}
            <a href="/auth/lock-screen" className="dropdown-item notify-item">
              <i className="fe-lock"></i>
              <span>Bloquear</span>
            </a>

            <div className="dropdown-divider"></div>

            {/* <!-- item--> */}
            <a href="/auth/logout" className="dropdown-item notify-item">
              <i className="fe-log-out"></i>
              <span>Salir</span>
            </a>

          </div>
        </li>
      </ul>

      <div className="logo-box">
        <a href="/student" className="logo logo-light text-center">
          <span className="logo-sm">
            <img src="/assets/images/logo-sm.png" alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-light.svg" alt="" height="16" />
          </span>
        </a>
        <a href="/student" className="logo logo-dark text-center">
          <span className="logo-sm">
            <img src="/assets/images/logo-sm.png" alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-dark.svg" alt="" height="120" />
          </span>
        </a>
      </div>

      <ul className="list-unstyled topnav-menu topnav-menu-left mb-0">
        <li>
          <button className="button-menu-mobile disable-btn waves-effect">
            <i className="fe-menu"></i>
          </button>
        </li>

        <li>
          <h4 className="page-title-main">Empresa Colombiana De Educación en Salud</h4>
        </li>
      </ul>

      <div className="clearfix"></div>
    </div>
  );
}

export default NavbarAdmin;