import React from 'react';

const MyCertificationsList = (props) => {
  const { entities } = props;

  const getPrincipalImage = (_entity) => {
    const defaultItem = {
      uri: `${process.env.REACT_APP_BACKEND_URL}/img/404.svg`,
      name:
        !_entity || !_entity.name
          ? 'Entidad no disponible'
          : `Adjunto inexistente de "${_entity.name}"`,
      attachmentType: 'image/svg+xml',
    };

    if (!_entity || !_entity.attachments || !_entity.attachments.length) {
      return defaultItem;
    }

    const element = [..._entity.attachments].find(
      (el) => el.attachmentType.includes('image') && el.isPrincipal === true,
    );

    if (!element || !element.uri) {
      return defaultItem;
    }

    return element;
  };

  const formatDate = (timestamp) => {
    const date = new Date(+timestamp);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  return (
    <div className="d-flex row">
      {entities.map((_entity) => (
        <div key={_entity.id} className="col-12 col-md-4 my-3">
          <div className="card d-flex h-100 justify-content-between shadow">
            <img
              className="card-img-top h-50"
              src={getPrincipalImage(_entity.course).uri}
              alt={_entity.course.name}
            />
            <div className="card-body">
              <section className="d-flex flex-column h-100 justify-content-between ">
                <div className="w-100">
                  <h5 className="card-title fs-4">
                    {formatDate(_entity.date_creation)} - {_entity.course.name}
                  </h5>
                </div>
                <footer className="mt-3 w-100">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                    href={`/student/certification/detail/${_entity.id}`}
                  >
                    Ver más
                  </a>
                </footer>
              </section>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyCertificationsList;
