import React, { useState, useEffect } from 'react';
import { CourseResourceService } from '@services';
import { useOutletContext } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import Alert from '@components/shared/alert/Alert';
import DocumentViewerComponent from '@components/shared/documentviewer/DocumentViewerComponent';

const CourseResourceDetailView = () => {
  const [entity, setEntity] = useState({
    status: {},
    attachments: [{ uri: '' }],
  });
  const { courseResourceId } = useParams();
  const { setPageName } = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirm, setIsConfirm] = useState(null);
  const [view, setView] = useState('lobby');
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  const getSelectedEntity = async () => {
    try {
      const entityService = new CourseResourceService();
      const entityResponse = await entityService.get({
        id: courseResourceId,
      });

      if (!entityResponse || !entityResponse.success) {
        setAlertInfo({
          message: 'Algo salió mal, por favor intente de nuevo.',
          type: 'error',
        });
        setIsLoading(false);
        return;
      }

      setEntity(entityResponse.result);
      setIsLoading(false);
    } catch (error) {
      setAlertInfo({
        message: 'Algo salió mal, por favor intente de nuevo.',
        type: 'error',
      });
      console.error('An error occurred:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPageName('Recursos del curso');
    getSelectedEntity();
  }, [courseResourceId]);

  useEffect(() => {
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const getBody = (body) => {
    if (!body) {
      return '';
    }

    return window.decodeURI(body);
  };

  const onlineOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    setView('viewer');
    setEntity((prevState) => ({
      ...prevState,
      attachments: prevState.attachments.length && [
        {
          ...prevState.attachments[0],
          uri: prevState.attachments[0].uri + ' ',
        },
      ],
    }));
  };

  const returnOnClick = () => {
    setView('lobby');
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  return (
    <div className="col-12 mx-auto d-block card">
      <div className="card-body">
        <div className="col-12 mx-auto">
          {alertInfo.type && (
            <div className="my-3">
              <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
            </div>
          )}
          {isLoading ? (
            <Loader />
          ) : (
            <div className="card-body">
              <h4 className="card-title text-center my-3">{entity.name}</h4>
              {view === 'lobby' && (
                <div className="border pd-1 rounded bg-light shadow-sm">
                  {entity.attachments.length > 0 || entity.description ? (
                    <section className="d-flex flex-column flex-md-row justify-content-center my-4 py-3">
                      <a
                        className="btn btn-primary me-md-2 mb-2"
                        href={
                          entity.attachments.length && entity.attachments[0].uri
                        }
                      >
                        Descargar material
                      </a>
                      <button
                        className="btn btn-success ms-md-2 mb-2"
                        onClick={onlineOnClick}
                      >
                        Ver online
                      </button>
                    </section>
                  ) : (
                    <section className="d-flex flex-column flex-md-row justify-content-center my-4 py-3">
                      <div>
                        Lo sentimos, no hay material disponible en este momento.
                      </div>
                    </section>
                  )}
                </div>
              )}

              {view === 'viewer' && (
                <div className="mt-4 border pd-1 rounded bg-light shadow-sm">
                  {entity.description && (
                    <div
                      className="body-content mb-4"
                      dangerouslySetInnerHTML={{
                        __html: getBody(entity.description),
                      }}
                    ></div>
                  )}
                  {entity.attachments.length > 0 && (
                    <div>
                      <section className="mb-3">
                        <DocumentViewerComponent
                          url={entity.attachments[0].uri}
                          fileType={entity.attachments[0].attachmentType}
                        />
                      </section>
                    </div>
                  )}
                  <button
                    title="Volver"
                    type="button"
                    className="btn btn-primary mb-3 btn-action mx-2"
                    onClick={returnOnClick}
                  >
                    <i className="bi bi-x"></i> Volver
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseResourceDetailView;
