import React, { useEffect, useState } from 'react';
import { useAuth } from '@hooks/useAuth.hook';
import SearchableSelect from './components/SearchableSelect';
import { FeedbackService, CourseService } from '@services/';
import Alert from '@components/shared/alert/Alert';
import { useNavigate } from 'react-router-dom';
import Rating from '@mui/material/Rating';

async function createEntity(payload, Entity) {
  const entityService = new Entity();
  const entityResponse = await entityService.create(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const FeedbackCreate = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [courseOptions, setCourseOptions] = useState({});
  const [entity, setEntity] = useState({
    is_private: false,
    score: 5,
  });
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  const showErrorAlert = (message) => setAlertInfo({ message, type: 'error' });
  const showSuccessAlert = (message) =>
    setAlertInfo({ message, type: 'success' });

  const getAllCourses = async () => {
    try {
      const entityService = new CourseService();
      const entityResponse = await entityService.get({
        all: true,
      });

      if (!entityResponse || !entityResponse.success) {
        showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
        return;
      }

      const entityAdapterForOptions = entityResponse.result.map((_entity) => ({
        value: _entity.id,
        label: _entity.name,
      }));
      setCourseOptions(entityAdapterForOptions);
      setCourses(entityResponse.result);
    } catch (error) {
      console.log(error);
      showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
    }
  };

  useEffect(() => {
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  useEffect(() => {
    getAllCourses();
  }, []);

  const confirmOnClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!entity.body) {
      return showErrorAlert(
        'Ha ocurrido un error. Por favor intenta más tarde',
      );
    }

    const course = courses.find((_entity) => _entity.id === entity.course_id);
    const data = {
      ...entity,
      score: +entity.score,
      user,
      user_id: user.id,
      course,
    };

    try {
      const response = await createEntity(data, FeedbackService);

      if (!response || !response.success) {
        showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
        return;
      }

      showSuccessAlert(
        'Gracias por dejarnos tu comentario, entre todos ayudamos a mejorar nuestra educación.',
      );
      resetForm();
    } catch (error) {
      console.error(error);
      showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => setEntity({ is_private: false, score: 5, body: '' });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setEntity((prevEntity) => ({
      ...prevEntity,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const cancelOnClick = () => navigate('/student/dashboard/');

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'success':
        return {
          title: 'Success',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  return (
    <form className="w-75 mx-auto">
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      <div className="row mb-3">
        <div className="col-12">
          <label className="form-label">Curso</label>
          <SearchableSelect
            options={courseOptions}
            onChange={(event) =>
              setEntity((prevState) => ({
                ...prevState,
                course_id: event.value,
              }))
            }
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-md-6">
          <label className="form-label">¿Enviar como anónimo?</label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="published-status-input"
              name="is_private"
              checked={entity.is_private}
              onChange={handleInputChange}
            />
            <label
              className="form-check-label"
              htmlFor="published-status-input"
            >
              {entity.is_private ? 'Si' : 'No'}
            </label>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex flex-column ">
            <label className="form-label">Puntaje</label>
            <Rating
              name="score"
              defaultValue={entity.score}
              size="large"
              onChange={handleInputChange}
            />
          </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <label className="form-label">Mensaje</label>
          <textarea
            className="form-control"
            id="body"
            name="body"
            value={entity.body}
            onChange={handleInputChange}
            placeholder=""
          ></textarea>
        </div>
      </div>
      <div className="mt-4">
        {isLoading ? (
          <button className="btn btn-primary" disabled type="button">
            <span
              className="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        ) : (
          <>
            <button
              title="Submit"
              type="submit"
              className="btn btn-primary btn-action mr-2"
              onClick={confirmOnClick}
            >
              <i className="fe-check"></i> Hacer recomendación
            </button>
            <button
              title="Cancel"
              type="button"
              className="btn btn-light btn-action ml-2"
              onClick={cancelOnClick}
            >
              <i className="fe-x"></i> Cancelar
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default FeedbackCreate;
