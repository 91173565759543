import React, { useState, useEffect, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth.hook';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import * as Icons from 'react-feather';
import Alert from '@components/shared/alert/Alert';

import {
  CourseService,
  CourseModuleService,
  CourseResourceService,
  UserCourseService,
  UserExamService,
  UserService,
} from '@services';

function CourseDetailView() {
  const { setPageName } = useOutletContext();

  const { courseId } = useParams();
  const { user } = useAuth();

  const [entity, setEntity] = useState({});
  const [userEntity, setUserEntity] = useState({});
  const [userCourse, setUserCourse] = useState({});
  const [userExam, setUserExam] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [modules, setModules] = useState([]);
  const [principalImage, setPrincipalImage] = useState([]);
  const [enrolled, setEnrolled] = useState(false);
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  useEffect(() => {
    setPageName('Cursos');
    const getEntityById = async () => {
      const entityService = new CourseService();

      try {
        const entityResponse = await entityService.get({ id: courseId });

        if (!entityResponse || !entityResponse.success) return;

        await getAllEntities();

        setEntity(entityResponse.result);
        setPrincipalImage(getPrincipalImage(entityResponse.result));
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getEntityById();
  }, [courseId]);

  useEffect(() => {
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const registerToCourseOnClick = async () => {
    setIsRegisterLoading(true);
    try {
      const usercourseService = new UserCourseService();
      const entityResponse = await usercourseService.create({
        user_id: user.id,
        user: userEntity,
        course_id: entity.id,
        course: entity,
      });

      if (!entityResponse || !entityResponse.success) {
        setIsRegisterLoading(false);
        return;
      }

      setAlertInfo({
        message:
          '¡Gracias por preinscribirte! Por favor, procede con el pago del curso. Si ya has realizado el pago, puedes ignorar este mensaje.',
        type: 'success-register',
      });

      setEnrolled(true);
      setIsRegisterLoading(false);
    } catch (error) {
      console.error(error);
      setAlertInfo({
        message: 'Algo salió mal, por favor intente de nuevo.',
        type: 'error',
      });
    }
  };

  const finishCourseOnClick = async () => {
    setIsRegisterLoading(true);
    try {
      const userexamService = new UserExamService();
      const usercourseService = new UserCourseService();

      let entityResponse = await userexamService.create({
        user_id: user.id,
        user: userEntity,
        status: { id: 5, name: 'notassigned', title: 'Sin examen asignado' },
      });

      if (!entityResponse || !entityResponse.success) {
        setIsRegisterLoading(false);
        return;
      }

      setUserCourse((prevState) => ({
        ...prevState,
        status: { id: 5, name: 'finished', title: 'Curso finalizado' },
      }));

      entityResponse = await usercourseService.update({
        ...userCourse,
        status: {
          id: 5,
          name: 'finished',
          title: 'Curso finalizado',
        },
      });

      if (!entityResponse || !entityResponse.success) {
        setIsRegisterLoading(false);
        return;
      }

      setAlertInfo({
        message: 'En breve verás en esta página el acceso al examen',
        type: 'success-finish',
      });

      setEnrolled(true);
      setIsRegisterLoading(false);
    } catch (error) {
      console.error(error);
      setAlertInfo({
        message: 'Algo salió mal, por favor intente de nuevo.',
        type: 'error',
      });
    }
  };

  const getPrincipalImage = (_entity) => {
    const defaultItem = [
      {
        uri: `${process.env.REACT_APP_BACKEND_URL}/img/404.svg`,
        name:
          !_entity || !_entity.name
            ? 'Entidad no disponible'
            : `Adjunto inexistente de "${_entity.name}"`,
        attachmentType: 'image/svg+xml',
      },
    ];

    if (
      !_entity ||
      !_entity.attachments.length ||
      !_entity.attachments[0].uri
    ) {
      return defaultItem;
    }

    return _entity.attachments;
  };

  const getBody = (body) => {
    if (!body) {
      return '';
    }

    const decoded = window.decodeURI(window.unescape(body));

    let text;
    if (decoded.length > 55) {
      text = decoded.slice(0, 55) + '...';
    } else {
      text = decoded;
    }

    return extractTextFromHtml(text);
  };

  const extractTextFromHtml = (richText) => {
    const span = document.createElement('span');
    span.innerHTML = richText;
    return span.textContent || span.innerText;
  };

  const getHtml = (body) => {
    if (!body) {
      return '';
    }

    return decodeURI(body);
  };

  const DynamicIcon = ({ iconName }) => {
    const IconComponent = Icons[iconName] || Icons.FileText;
    return <IconComponent className="d-block my-auto" />;
  };

  const getAllEntities = async () => {
    const moduleService = new CourseModuleService();
    const resourceService = new CourseResourceService();
    const usercourseService = new UserCourseService();
    const userexamService = new UserExamService();
    const userService = new UserService();

    try {
      const [
        moduleResponse,
        resourceResponse,
        usercourseResponse,
        userexamResponse,
        userResponse,
      ] = await Promise.all([
        moduleService.get({ courseid: courseId }),
        resourceService.get({ courseid: courseId }),
        usercourseService.get({ userId: user.id }),
        userexamService.get({ userId: user.id }),
        userService.get({ identity: user.id }),
      ]);

      if (
        !moduleResponse ||
        !resourceResponse ||
        !usercourseResponse ||
        !userexamResponse ||
        !userResponse
      )
        return;

      setUserEntity(userResponse.result);
      digestResources(moduleResponse.result, resourceResponse.result);
      digestUserCourse(usercourseResponse.result);
      digestUserExam(userexamResponse.result);
    } catch (error) {
      console.error(error);
    }
  };

  const digestResources = (modules, resources) => {
    setModules(modules);

    let updatedModules = [...modules];

    resources.map((resource) => {
      const moduleIndex = updatedModules.findIndex(
        (mod) => mod.id === resource.module_id,
      );
      if (moduleIndex !== -1) {
        if (!updatedModules[moduleIndex].resources) {
          updatedModules[moduleIndex].resources = [];
        }
        updatedModules[moduleIndex].resources.push(resource);
      }
    });

    updatedModules.map((module) => {
      if (module.resources) {
        module.resources.sort((a, b) => a.order - b.order);
      }
    });

    setModules(updatedModules);

    const singleResources = resources.filter((resource) => !resource.module_id);
    setResources(singleResources);

    renderIcons();
  };

  const digestUserCourse = (userCourses) => {
    if (!userCourses || !userCourses.length) {
      setUserCourse(undefined);
      return;
    }
    const foundUserCourse = userCourses.find(
      (userCourse) => userCourse.course_id === courseId,
    );
    setUserCourse(foundUserCourse);
  };

  const digestUserExam = (userexams) => {
    if (!userexams || !userexams.length) {
      setUserExam(undefined);
      return;
    }
    const foundUserExams = userexams.filter((userExam) => {
      if (!userExam.exam) return false;
      return userExam.exam.course_id === courseId;
    });
    setUserExam(foundUserExams);
  };

  const renderIcons = () => {
    setTimeout(() => {
      try {
        window.feather.replace();
      } catch (_) {}
    }, 50);
  };

  const c2aToCourseOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const btn = document.querySelector('#enrollment-tab');
    if (btn) {
      btn.click();
    }

    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  const orderedTags = useMemo(() => {
    if (entity && entity.tags) {
      return entity.tags.split(',');
    }
    return [];
  }, [entity]);

  const orderedResources = useMemo(() => {
    return resources.sort((a, b) => a.order - b.order);
  }, [resources]);

  const orderedModules = useMemo(() => {
    return modules.sort((a, b) => a.order - b.order);
  }, [modules]);

  const orderedExams = useMemo(() => {
    return userExam;
  }, [userExam]);

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'success-register':
        return {
          title: '¡Te has preinscrito!',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'success-finish':
        return {
          title: '¡Has finalizado el curso!',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  return (
    <div className="col-12 mx-auto d-block card">
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card-body">
          <div className="col-12 mx-auto my-2 d-block">
            <div className="container pt-2">
              <div className="row d-flex">
                <section className="col-12">
                  <h3 className="card-title mb-4">{entity.name}</h3>
                </section>
                <section className="col-12">
                  <header>
                    <section className="d-flex flex-column flex-md-row flex-wrap mb-4 p-3 border rounded shadow-sm">
                      <div className="d-flex align-items-center mx-4 my-2">
                        <i data-feather="user" className="me-3"></i>
                        <div>
                          <h6 className="fw-bold mb-1">Profesor</h6>
                          <p className="mb-0">{entity.teacher_name}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mx-4 my-2">
                        <i data-feather="pocket" className="me-3"></i>
                        <div>
                          <h6 className="fw-bold mb-1">Categoría</h6>
                          <p className="mb-0">{entity.category}</p>
                        </div>
                      </div>
                      {!userCourse && (
                        <div className="mr-4 my-2">
                          <button
                            className="btn btn-lg btn-success"
                            onClick={(e) => c2aToCourseOnClick(e)}
                          >
                            ¡Inscríbete!
                          </button>
                        </div>
                      )}

                      {userCourse?.status?.name === 'approved' && (
                        <div className="d-flex align-items-center mx-4 my-2">
                          <span className="badge bg-info fs-5">
                            {userCourse.status.title}
                          </span>
                        </div>
                      )}
                      {userCourse?.status?.name === 'blocked' && (
                        <div className="d-flex align-items-center mx-4 my-2">
                          <span className="badge bg-danger fs-5">
                            {userCourse.status.title}
                          </span>
                        </div>
                      )}
                      {userCourse?.status?.name === 'finished' && (
                        <div className="d-flex align-items-center mx-4 my-2">
                          <span className="badge bg-success fs-5">
                            {userCourse.status.title}
                          </span>
                        </div>
                      )}
                    </section>
                    <div className="d-flex">
                      <img
                        className="img-fluid w-100 course-banner"
                        src={principalImage.length && principalImage[0].uri}
                        alt=""
                      />
                    </div>
                  </header>
                  <section className="my-4">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {userCourse?.status?.name === 'approved' && (
                        <>
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link active"
                              id="classes-tab"
                              data-bs-toggle="tab"
                              href="#classes"
                              role="tab"
                              aria-controls="classes"
                              aria-selected="false"
                            >
                              Material de estudio
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="general-tab"
                              data-bs-toggle="tab"
                              href="#general"
                              role="tab"
                              aria-controls="general"
                              aria-selected="true"
                            >
                              General
                            </a>
                          </li>
                        </>
                      )}
                      {(!userCourse ||
                        (userCourse &&
                          userCourse.status?.name !== 'approved')) && (
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link active"
                            id="general-tab"
                            data-bs-toggle="tab"
                            href="#general"
                            role="tab"
                            aria-controls="general"
                            aria-selected="true"
                          >
                            General
                          </a>
                        </li>
                      )}
                      {!userCourse && (
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="enrollment-tab"
                            data-bs-toggle="tab"
                            href="#enrollment"
                            role="tab"
                            aria-controls="enrollment"
                            aria-selected="false"
                          >
                            Inscripción
                          </a>
                        </li>
                      )}
                      {userCourse?.status?.name === 'approved' && (
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="finish-tab"
                            data-bs-toggle="tab"
                            href="#finish"
                            role="tab"
                            aria-controls="finish"
                            aria-selected="false"
                          >
                            Finalizar curso
                          </a>
                        </li>
                      )}
                      {userExam && userExam.length > 0 && (
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="exam-tab"
                            data-bs-toggle="tab"
                            href="#exam"
                            role="tab"
                            aria-controls="exam"
                            aria-selected="false"
                          >
                            Evaluaciones
                          </a>
                        </li>
                      )}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {userCourse?.status?.name === 'approved' && (
                        <section
                          className="tab-pane fade show active"
                          id="classes"
                          role="tabpanel"
                          aria-labelledby="classes-tab"
                        >
                          <article className="d-flex flex-column my-4">
                            <h4 className="mb-4">Nuestro programa</h4>

                            <div className="accordion" id="program-accordion">
                              {orderedModules.map((_entity, index) => (
                                <div
                                  key={index}
                                  className="card mb-3 shadow-sm"
                                >
                                  <div
                                    className="card-header bg-white"
                                    id={`heading${index}`}
                                  >
                                    <button
                                      className="btn btn-link text-start w-100 text-decoration-none"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#item-${index}`}
                                      aria-expanded="false"
                                      aria-controls={`item-${index}`}
                                    >
                                      <i
                                        className="me-3"
                                        data-feather="chevron-down"
                                      ></i>
                                      <span className="fw-bold text-muted">
                                        {_entity.name}
                                      </span>
                                      <br />
                                      <small className="text-secondary">
                                        {getBody(_entity.description)}
                                      </small>
                                    </button>
                                  </div>

                                  <article
                                    className="collapse"
                                    aria-labelledby={`heading${index}`}
                                    data-bs-parent="#program-accordion"
                                    id={`item-${index}`}
                                  >
                                    <div className="card-body">
                                      {_entity.resources &&
                                        _entity.resources.length &&
                                        _entity.resources.map((_resource) => (
                                          <section
                                            key={_resource.id}
                                            className="border-top d-flex w-100"
                                          >
                                            <article className="d-flex me-4 col-2">
                                              <div className="d-flex mx-3">
                                                <DynamicIcon
                                                  iconName={_resource.icon}
                                                />
                                              </div>
                                              <p className="h-6 my-3 text-muted text-truncate text-uppercase">
                                                {_resource.type_name}
                                              </p>
                                            </article>
                                            <article className="col-8">
                                              <p className="text-truncate my-3">
                                                <span className="fw-bold mx-1">
                                                  {_resource.name}:
                                                </span>
                                                {getBody(_resource.description)}
                                              </p>
                                            </article>
                                            <article className="col-2">
                                              {userCourse?.status?.name ===
                                                'approved' && (
                                                <a
                                                  className="btn btn-primary my-2"
                                                  href={`/student/course/resource/detail/${_resource.id}`}
                                                >
                                                  Ver
                                                </a>
                                              )}
                                            </article>
                                          </section>
                                        ))}
                                    </div>
                                  </article>
                                </div>
                              ))}
                            </div>

                            <div className="d-flex flex-column resources-container">
                              {orderedResources.map((_entity) => (
                                <section
                                  key={_entity.id}
                                  className="border-top d-flex w-100"
                                >
                                  <article className="d-flex me-4 col-2">
                                    <div className="d-flex mx-3">
                                      <DynamicIcon iconName={_entity.icon} />
                                    </div>
                                    <p className="h-6 my-3 text-muted text-truncate text-uppercase">
                                      {_entity.type_name}
                                    </p>
                                  </article>
                                  <article className="col-8">
                                    <p className="text-truncate my-3">
                                      <span className="fw-bold">
                                        {_entity.name}
                                      </span>
                                      : {getBody(_entity.description)}
                                    </p>
                                  </article>
                                  <article className="col-2">
                                    {userCourse?.status?.name ===
                                      'approved' && (
                                      <a
                                        className="btn btn-primary my-2"
                                        href={`/student/course/resource/detail/${_entity.id}`}
                                      >
                                        Ver
                                      </a>
                                    )}
                                  </article>
                                </section>
                              ))}
                            </div>
                          </article>
                        </section>
                      )}

                      {userCourse?.status?.name === 'approved' ? (
                        <section
                          className="tab-pane fade"
                          id="general"
                          role="tabpanel"
                          aria-labelledby="general-tab"
                        >
                          <article className="d-flex flex-column my-4">
                            <h4 className="mb-4">Descripción</h4>
                            <div
                              className="body-content"
                              dangerouslySetInnerHTML={{
                                __html: getHtml(entity.description),
                              }}
                            ></div>
                          </article>
                        </section>
                      ) : (
                        <section
                          className="tab-pane fade show active"
                          id="general"
                          role="tabpanel"
                          aria-labelledby="general-tab"
                        >
                          <article className="d-flex flex-column my-4">
                            <h4 className="mb-4">Descripción</h4>
                            <div
                              className="body-content"
                              dangerouslySetInnerHTML={{
                                __html: getHtml(entity.description),
                              }}
                            ></div>
                          </article>
                        </section>
                      )}

                      {!userCourse && (
                        <section
                          className="tab-pane fade my-4"
                          id="enrollment"
                          role="tabpanel"
                          aria-labelledby="enrollment-tab"
                        >
                          <h4 className="card-title mb-4">Inscripción</h4>
                          <p className="mb-4">
                            Los pasos para inscribirte al curso son:
                          </p>
                          <ul className="list-group list-group-flush">
                            {!enrolled && (
                              <li className="list-group-item d-flex align-items-center">
                                {isRegisterLoading ? (
                                  <Loader />
                                ) : (
                                  <div>
                                    <i
                                      data-feather="edit-2"
                                      className="me-3"
                                    ></i>
                                    Realizas la preinscripción en el siguiente
                                    botón
                                    <button
                                      className="btn btn-primary ms-2"
                                      onClick={() => registerToCourseOnClick()}
                                    >
                                      Click aquí para PREINSCRIBIRTE
                                    </button>
                                  </div>
                                )}
                              </li>
                            )}
                            <li className="list-group-item d-flex align-items-center">
                              <i
                                data-feather="credit-card"
                                className="me-3"
                              ></i>
                              Haces el pago en el siguiente botón
                              <a
                                href={entity.payment_link}
                                className="btn btn-primary ms-2"
                                target="_blank"
                              >
                                Click aquí para PAGAR
                              </a>
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                              <i
                                data-feather="message-circle"
                                className="me-3"
                              ></i>
                              Envía el comprobante de pago a
                              <a
                                className="btn btn-success mx-2"
                                target="_blank"
                                href="https://api.whatsapp.com/send?phone=573507829755&text=Hola!%20decid%C3%AD%20crecer%20en%20mi%20carrera%20profesional%20y%20ya%20pagu%C3%A9%20el%20curso,%20te%20adjunto%20el%20comprobante%20de%20pago:"
                              >
                                3507829755
                              </a>
                              por WhatsApp.
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                              <i data-feather="clock" className="me-3"></i>
                              Esperas a que se apruebe el pago y lo podamos
                              verificar
                            </li>
                            <li className="list-group-item d-flex align-items-center">
                              <i data-feather="book-open" className="me-3"></i>
                              Finalmente, comienza tus estudios para crecer en
                              tu carrera profesional!
                            </li>
                          </ul>
                        </section>
                      )}

                      {userCourse?.status?.name === 'approved' && (
                        <section
                          className="tab-pane fade"
                          id="finish"
                          role="tabpanel"
                          aria-labelledby="finish-tab"
                        >
                          <article className="d-flex flex-column my-4">
                            <h3 className="text-center mt-4">
                              ¿Haz finalizado el curso?
                            </h3>
                            <h6 className="mb-4 text-center">
                              Ahora, es momento de presentar la evaluación
                            </h6>
                            <div className="d-flex justify-content-center mb-4">
                              <button
                                className="btn btn-primary btn-rounded btn-lg text-center"
                                onClick={() => finishCourseOnClick()}
                              >
                                Finalizar curso
                              </button>
                            </div>
                          </article>
                        </section>
                      )}

                      {userExam && userExam.length > 0 && (
                        <section
                          className="tab-pane fade"
                          id="exam"
                          role="tabpanel"
                          aria-labelledby="exam-tab"
                        >
                          <article className="d-flex flex-column my-4">
                            <h3 className="text-center mt-2">
                              ¿Estás preparado para la evaluación?
                            </h3>
                            <h6 className="mb-4 text-center">
                              Has click en el siguiente enlace para iniciar la
                              evaluación
                            </h6>
                            <div className="d-flex justify-content-center mb-4">
                              <div className="d-flex flex-column resources-container w-100">
                                {orderedExams.map((_entity) => (
                                  <section
                                    key={_entity.exam_id}
                                    className="border-top d-flex w-100"
                                  >
                                    <article className="d-flex me-4 col-2">
                                      <div className="d-flex me-3">
                                        <i
                                          className="d-block my-auto"
                                          data-feather="check-square"
                                        ></i>
                                      </div>
                                      <p className="h-6 my-3 text-muted text-truncate text-uppercase">
                                        Examen
                                      </p>
                                    </article>
                                    <article className="col-8">
                                      <p className="text-truncate my-3">
                                        <span className="fw-bold">
                                          {_entity.exam.name}
                                        </span>
                                      </p>
                                    </article>
                                    <article className="col-2">
                                      <a
                                        className="btn btn-primary my-2"
                                        href={`/student/exam/detail/${_entity.exam_id}`}
                                      >
                                        Iniciar evaluación
                                      </a>
                                    </article>
                                  </section>
                                ))}
                              </div>
                            </div>
                          </article>
                        </section>
                      )}
                    </div>

                    <section>
                      {orderedTags && orderedTags.length > 0 ? (
                        <footer className="d-flex my-2 pt-3 border-top">
                          <div className="me-2 my-auto text-secondary">
                            <strong>Tags:</strong>
                          </div>
                          <section className="d-flex flex-wrap align-items-center">
                            {orderedTags &&
                              orderedTags.length &&
                              orderedTags.map((_tag) => (
                                <span
                                  key={_tag}
                                  className="badge bg-primary text-white p-1 mx-1 my-1"
                                >
                                  {_tag}
                                </span>
                              ))}
                          </section>
                        </footer>
                      ) : (
                        <p className="text-muted">
                          <strong>Tags:</strong> No hay etiquetas.
                        </p>
                      )}
                    </section>
                  </section>
                </section>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseDetailView;
