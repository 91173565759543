import React, { useEffect, useState } from 'react';
import { CertificationService } from '@services/index';
import { useAuth } from '@hooks/useAuth.hook';
import Alert from '@components/shared/alert/Alert';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import { MyCertificationsList } from '@components/pages';

const MyCertificationView = () => {
  const { user } = useAuth();
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  const showErrorAlert = (message) => setAlertInfo({ message, type: 'error' });

  useEffect(() => {
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const entityService = new CertificationService();
        const entityResponse = await entityService.getMyCertifications({
          user_id: user.id,
        });

        if (!entityResponse || !entityResponse.success) {
          showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
          return;
        }

        setEntities(entityResponse.result);
      } catch (error) {
        console.error(error);
        showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [user.id]);

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'success':
        return {
          title: 'Success',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  return (
    <section className="container-fluid">
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      <div className="row">
        <article className="card card-body">
          <div className="col-12 d-block mx-auto py-5">
            <ul
              className="nav nav-tabs nav-bordered"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="mycertifications-tab"
                  data-bs-toggle="tab"
                  href="#mycertifications"
                  role="tab"
                  aria-controls="mycertifications"
                  aria-selected="false"
                >
                  Mis certificados
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              {/* My certifications */}
              {isLoading ? (
                <SpinnerLoader />
              ) : (
                <div
                  className="tab-pane fade show active"
                  id="mycertifications"
                  role="tabpanel"
                  aria-labelledby="mycertifications-tab"
                >
                  <MyCertificationsList entities={entities} />
                </div>
              )}
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default MyCertificationView;
