import React from 'react';
import { useAuth } from '../../hooks/useAuth.hook';

function SidebarAdmin() {
  const { user } = useAuth();

  return (
    <div className="left-side-menu">
      <div className="h-100 mt-4" data-simplebar>
        {/* <!-- User box --> */}
        <div className="user-box text-center">
          <div className="dropdown">
            <a
              href="#"
              className="user-name dropdown-toggle h5 mt-2 mb-1 d-block"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {user.firstname} {user.lastname}
            </a>
            <div className="dropdown-menu user-pro-dropdown">
              <a href="/student/account/overview" className="dropdown-item notify-item">
                <i className="fe-user me-1"></i>
                <span>Mi cuenta</span>
              </a>
              <a href="/auth/lock-screen" className="dropdown-item notify-item">
                <i className="fe-lock me-1"></i>
                <span>Bloquear</span>
              </a>
              <a href="/auth/logout" className="dropdown-item notify-item">
                <i className="fe-log-out me-1"></i>
                <span>Salir</span>
              </a>
            </div>
          </div>

          <p className="text-muted left-user-info">{user.role.title}</p>

          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="/auth/lock-screen">
                <i className="mdi mdi-power"></i>
              </a>
            </li>
          </ul>
        </div>

        {/* <!--- Sidemenu --> */}
        <div id="sidebar-menu">
          <ul id="side-menu">
            <li className="menu-title">Navegación</li>

            <li>
              <a href="/student/dashboard">
                <i className="mdi mdi-home"></i>
                <span> Inicio </span>
              </a>
            </li>

            <li>
              <a href="/student/course/my-courses">
                <i className="fe-book-open"></i>
                <span> Mis Cursos </span>
              </a>
            </li>

            <li>
              <a href="/student/certification/my-certifications">
                <i className="fe-award"></i>
                <span> Certificados </span>
              </a>
            </li>

            <li>
              <a href="/student/feedback/create">
                <i className="fe-message-circle"></i>
                <span> Déjanos tu comentario </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}

export default SidebarAdmin;
