import React, { useEffect, useState } from 'react';
import { CourseService } from '@services';
import { useAuth } from '@hooks/useAuth.hook';
import { MyCourseList } from '@components/pages';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import Alert from '@components/shared/alert/Alert';

async function fetchMyCourses(query, setEntities, setIsLoading, setAlertInfo) {
  try {
    const entityService = new CourseService();
    const entityResponse = await entityService.getMyCourses(query);

    if (!entityResponse || !entityResponse.success) {
      setAlertInfo({
        message: 'Ha ocurrido un error. Por favor intenta mas tarde',
        type: 'error',
      });
      return;
    }

    setEntities(entityResponse.result);
  } catch (error) {
    console.error(error);
    setAlertInfo({
      message: 'Ha ocurrido un error. Por favor intenta mas tarde',
      type: 'error',
    });
  } finally {
    setIsLoading(false);
  }
}

const VIEW_MY_COURSES = 'myCourses';

function MyCoursesView() {
  const { user } = useAuth();
  const [entities, setEntities] = useState([]);
  const [filteredMyCourses, setFilteredMyCourses] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [view, setView] = useState(VIEW_MY_COURSES);
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  const resetFilteredEntities = () => {
    setFilteredMyCourses(entities);
  };

  useEffect(() => {
    fetchMyCourses(
      {
        user_id: user.id,
      },
      setEntities,
      setIsLoading,
      setAlertInfo,
    );
  }, []);

  useEffect(() => {
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  useEffect(() => {
    if (!searchText) {
      resetFilteredEntities();
      return;
    }

    switch (view) {
      case VIEW_MY_COURSES:
        const lowerCaseSearchText = searchText.toLowerCase();
        setFilteredMyCourses(() =>
          entities.filter((item) =>
            item.name.toLowerCase().includes(lowerCaseSearchText),
          ),
        );
        break;
      default:
        break;
    }
  }, [searchText, view, entities]);

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'success':
        return {
          title: 'Success',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  return (
    <section className="container-fluid">
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      <div className="row">
        <article className="card card-body">
          <div className="col-12 d-block mx-auto py-5">
            <div className="d-flex justify-content-between">
              <ul
                className="nav nav-tabs nav-bordered"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="mycourses-tab"
                    data-bs-toggle="tab"
                    href="#mycourses"
                    role="tab"
                    aria-controls="mycourses"
                    aria-selected="false"
                    onClick={() => setView(VIEW_MY_COURSES)}
                  >
                    Mis cursos
                  </a>
                </li>
              </ul>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="search-course"
                  id="search-course"
                  placeholder="Buscar..."
                  value={searchText}
                  onChange={({ target: { value } }) => setSearchText(value)}
                />
              </div>
            </div>
            <div className="tab-content" id="myTabContent">
              {/* My courses */}
              {isLoading ? (
                <SpinnerLoader />
              ) : (
                <section
                  className="tab-pane fade show active"
                  id="mycourses"
                  role="tabpanel"
                  aria-labelledby="mycourses-tab"
                >
                  <MyCourseList filteredEntities={filteredMyCourses} />
                </section>
              )}
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}

export default MyCoursesView;
