import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import {
  AccountGettingStarted,
  AccountOverview,
  AccountProfile,
  AuthLogin,
  AuthLogout,
  AuthBridge,
  AuthLockscreen,
  BusinessCreate,
  BusinessList,
  BusinessUpdate,
  CourseDetailView,
  MyCoursesView,
  DashboardAdmin,
  DashboardBusiness,
  FeedbackView,
  MaintenanceGeneral,
  MyCertificationView,
  CertificationDetailView,
  UserCreate,
  UserList,
  UserUpdate,
  Dashboard,
  CourseResourceDetailView,
  ExamDetailView,
} from './pages/index';

import {
  LayoutAdmin,
  LayoutAuth,
  LayoutMaintenance,
  LayoutBusiness,
} from './layouts/index';
import { AuthProvider } from './hooks/useAuth.hook';
import { SnackbarProvider } from '@components/utilities/SnackbarProvider';

function App() {
  return (
    <BrowserRouter>
    <SnackbarProvider>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LayoutAuth />}>
            <Route index element={<AuthBridge />} />
          </Route>
          <Route path="/auth" element={<LayoutAuth />}>
            <Route index element={<MaintenanceGeneral />} />
            <Route path="login" element={<AuthLogin />} />
            <Route path="logout" element={<AuthLogout />} />
            <Route path="lock-screen" element={<AuthLockscreen />} />
          </Route>
          <Route path="/student" element={<LayoutAdmin />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="feedback">
              <Route path="create" element={<FeedbackView />} />
            </Route>
            <Route path="certification">
              <Route
                path="my-certifications"
                element={<MyCertificationView />}
              />
              <Route path="detail/:id" element={<CertificationDetailView />} />
            </Route>
            <Route path="course">
              <Route path="my-courses" element={<MyCoursesView />} />
              <Route path="detail/:courseId" element={<CourseDetailView />} />
              <Route path="resource">
                <Route
                  path="detail/:courseResourceId"
                  element={<CourseResourceDetailView />}
                />
              </Route>
            </Route>
            <Route path="account">
              <Route index path="overview" element={<AccountOverview />} />
              <Route element={<AccountGettingStarted />} />
              <Route path="profile" element={<AccountProfile />} />
            </Route>
            <Route path="business">
              <Route index element={<BusinessList />} />
              <Route path="create" element={<BusinessCreate />} />
              <Route path="update" element={<BusinessUpdate />} />
            </Route>
            <Route path="exam">
              <Route path="detail/:id" element={<ExamDetailView />} />
            </Route>
            <Route path="user">
              <Route index element={<UserList />} />
              <Route path="create" element={<UserCreate />} />
              <Route path="update" element={<UserUpdate />} />
            </Route>
            <Route path="*" element={<MaintenanceGeneral />} />
          </Route>
          <Route path="/business" element={<LayoutBusiness />}>
            <Route index element={<DashboardBusiness />} />
            <Route path="account">
              <Route index path="overview" element={<AccountOverview />} />
              <Route
                path="getting-started"
                element={<AccountGettingStarted />}
              />
              <Route path="profile" element={<AccountProfile />} />
            </Route>
            <Route path="user">
              <Route index element={<UserList />} />
              <Route path="create" element={<UserCreate />} />
              <Route path="update" element={<UserUpdate />} />
            </Route>
          </Route>

          <Route path="*" element={<LayoutMaintenance />}>
            <Route path="*" element={<MaintenanceGeneral />} />
          </Route>
        </Routes>
      </AuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
