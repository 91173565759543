import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { FeedbackCreate } from '@components/pages';

function FeedbackView() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Recomendaciones');
  }, []);

  return (
    <section className="container-fluid">
      <div className="row ">
        <article className="card card-body">
          <FeedbackCreate />
        </article>
      </div>
    </section>
  );
}

export default FeedbackView;
