import BaseApi from '../base/apiBase.service';

export default class FeedbackService extends BaseApi {
  constructor(args) {
    super(args);

    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_BACKEND_URL,
      get: '/api/feedback',
      create: '/api/feedback',
      update: '/api/feedback',
    };
  }
  async getByParameters(data) {
    return super.getByParameters(data);
  }

  async update(data) {
    return super.update(data);
  }

  async create(data) {
    return super.create(data);
  }
}
